import React from 'react';
import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Gallery.css';

const Gallery = () => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === 'left') {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <div className="app__gallery flex__center">
      <div className="app__gallery-content">
      <SubHeading title="Öffnungszeiten & Kontakt" />
      <div className="app__wrapper-content">
        <p className="p__opensans">Montag: Ruhetag</p>
        <p className="p__opensans">Dienstag: Ruhetag</p>
        <p className="p__opensans">Mittwoch: 16:00 - 21:00</p>
        <p className="p__opensans">Donnerstag: 16:00 - 21:00</p>
        <p className="p__opensans">Freitag: 16:00 - 21:30</p>   
        <p className="p__opensans">Samstag: 14:00 - 21:30</p>
        <p className="p__opensans">Sonntag & Feiertags: 14:00 - 21:00</p>
        <p>""</p>
        <p className="p__opensans">Sandweg 59, 50827 Köln</p>
        <p>""</p>
        <p className="p__opensans">Vorbestellen und abholen</p>
        <p className="p__opensans">Telefon: <a href="tel:+4922193117898">0221 93117898</a> </p>
        <p className="p__opensans">Mobil: <a href="tel:+4915202033288">0152 02033288</a> </p>
        <p>""</p>
        <p className="p__opensans">Catering Service gerne auf Anfrage</p>
        {/* <p className="p__cormorant" style={{ color: '#ce190cd7', margin: '2rem 0' }}>Öffnungszeiten</p>*/}

      </div>

        {/* <h1 className="headtext__cormorant">Fotogalerie</h1>
        <p className="p__opensans" style={{ color: '#AAAAAA', marginTop: '2rem' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisque egestas mu.</p>
        <a href="https://www.google.com/maps/uv?pb=!1s0x47bf25635d32cb53%3A0x364098d9b79de5ed!3m1!7e115!4shttps%3A%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipNJFqMyGMxFAZJwljotP4xwxxUQg3RJz3fixYZY%3Dw260-h175-n-k-no!5sbest%20sushi%20-%20Google%20Suche!15sCgIgAQ&imagekey=!1e10!2sAF1QipNJFqMyGMxFAZJwljotP4xwxxUQg3RJz3fixYZY&hl=de&sa=X&ved=2ahUKEwid9OqY3a7-AhU3xQIHHaiWCB0Q7ZgBKAB6BAgXEAI">       
        <button type="button" className="custom__button">Mehr Fotos</button>
        </a>*/} 

        <a href="https://goo.gl/maps/Kub9wjao6ht8bQN7A">
          <button type="button" className="custom__button" style={{ marginTop: '2rem' }}>Maps öffnen</button>
        </a>

      </div>
      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
          {[images.gallery01, images.gallery02, images.gallery03, images.gallery04, images.gallery05].map((image, index) => (
            <div className="app__gallery-images_card flex__center" key={`gallery_image-${index + 1}`}>
              <img src={image} alt="gallery_image" />
              <BsInstagram className="gallery__image-icon" />
            </div>
          ))}
        </div>
        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort className="gallery__arrow-icon" onClick={() => scroll('left')} />
          <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll('right')} />
        </div>
      </div>
    </div>
  );
};

export default Gallery;