import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';

import { FooterOverlay } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (

<div className="app__bg app__wrapper section__padding" id="contact">

  <div className="app__footer section__padding" id="login">
    <FooterOverlay />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Kontakt</h1>
        <p className="p__opensans">The best Sushi</p>
        <p className="p__opensans">Sandweg 59, 50827 Köln</p>
        <p className="p__opensans">Vorbestellen und abholen</p>
        <p className="p__opensans">Telefon: <a href="tel:+4922193117898">0221 93117898</a> </p>
        <p className="p__opensans">Mobil: <a href="tel:+4915202033288">0152 02033288</a> </p>
        <p className="p__opensans">Catering Service gerne auf Anfrage</p>
      </div>

      <div className="app__footer-links_logo">
        <img src={images.gericht} alt="footer_logo" />
        <p className="p__opensans">&quot;Sehr leckere Speisen, fein abgestimmte Aromen. Sushi extrem lecker.&quot;</p>
        <img src={images.spoon} alt="app__logo" className="spoon__img" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
          <FiFacebook />
          <FiTwitter />
          <FiInstagram />
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Öffnungszeiten</h1>
        <p className="p__opensans">Montag: Ruhetag</p>
        <p className="p__opensans">Dienstag: Ruhetag</p>
        <p className="p__opensans">Mittwoch: 16:00 - 21:00</p>
        <p className="p__opensans">Donnerstag: 16:00 - 21:00</p>
        <p className="p__opensans">Freitag: 16:00 - 21:30</p>   
        <p className="p__opensans">Samstag: 14:00 - 21:30</p>
        <p className="p__opensans">Sonntag & Feiertags: 14:00 - 21:00</p>
      </div>
    </div>
    <div className="footer__copyright">
    <p className="p__opensans"><a href="https://drive.google.com/file/d/1J6QAxKRRE6y8AMilY2ojbg35vrkNTsMA/view?usp=drive_link">Impressum </a>+ <a href="https://drive.google.com/file/d/19FQz2vc8EEU8BoPObfcxmXK9LS79vNtK/view?usp=sharing">Privacy Policy</a></p>
    </div>
    <div className="footer__copyright">
      <p className="p__opensans">Wir verwenden ausschließlich technisch notwendige Cookies auf unserer Website.</p>
    </div>

  </div>
  </div>
);

export default Footer;